@use "../abstracts" as *;
@import "../../variables";

%textStyle {

  font-family: $font-main;
  font-size: size(text, 500);
  font-weight: 400;
  font-style: normal;
  text-align: center;
  color: $blue;

  &:hover {
    color: $blue;
  }

  @include for-phone-only {
    color: color(grayScale, white);
  }
}

.header__nav {
  @include for-phone-only {
    margin-right: 1.5rem;
  }
}

.nav {
  width: 100%;
  @extend %transitionDefault;

  &__list {
    width: 100%;

    &--drop {
      top: 100%;
      left: 0;
      z-index: 100;
      flex-direction: column !important;
      align-items: flex-start !important;
      justify-content: flex-start !important;
      padding: 0.5rem;
      @extend %transitionDefault;
    }
  }

  &__link {
    color: #4D4D4D !important;
    width: 100%;
    height: 100%;
    font-family: Lato;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: uppercase;
    @include flex(flex, column, center, center);

    @include for-phone-only {
      @include flex(flex, column, flex-start, center);
    }
  }

  &__item {
    position: relative;
    color: #0000;
    padding: 0 0.5rem;
    cursor: default;
    margin-bottom: 5px;

    @include for-tablet-portrait-only {
      margin-bottom: 30px !important;
    }

    &::after {
      @extend %transitionDefault;
      content: "";
      display: block;
      height: 2px;
      width: 0px;
      margin: 0 auto;
      background-color: color(primary, 300);

      @include for-phone-only {
        height: 1px;
        margin: unset;
      }
    }

    @include for-desktop-up {
      border: none !important;
      margin-bottom: 0px;
    }

    &:hover {
      &::after {
        width: 75%;
      }
    }

    &:not(:last-of-type) {
      margin-right: 0rem;

      @include for-desktop-up {
        margin-right: 0;
      }
    }

    &--drop {
      position: relative;

      span {
        @extend %textStyle;

        @include for-phone-only {
          justify-content: flex-start !important;
        }
      }

      &-item {
        position: relative;
        width: 100%;

        a {
          padding: 0.5rem 0;
        }

        &::after {
          height: 0px;
        }

        &:hover {
          a {
            color: #131313 !important;
          }
        }
      }

      &:hover {
        background-color: white;

        span {
          color: color(primary) !important;
        }

        &>ul {
          opacity: 1;
          visibility: visible;
          box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }

  &__button {
    background-color: #ED1B24;
    color: white;
    width: 100%;
    border-radius: 31px;
    padding: 16px 30px 16px 30px;
    display: flex;
    justify-content: center;

    img {
      object-fit: contain;
      height: 28px;
    }

    span {
      text-transform: uppercase;
      color: white;

      font-family: Lato;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  @include for-desktop-up {
    &__button {
      width: 159px;
    }
  }

  @include for-phone-only {
    &__list {
      padding: 10px 10px;

      &--drop {
        a {
          text-align: left;
          color: #000000 !important;
        }
      }
    }

    &__button {
      width: 100%;
    }

    &__item {
      margin-bottom: 1rem;

      a {
        color: #000000;
      }
    }
  }

  @include for-desktop-up {
    &__content {
      display: block;
    }

    &__list {
      @include flex(flex, row, center, space-between);

      padding: 0px 10px;

      &--drop {
        position: absolute;
        min-width: 200px;
        opacity: 0;
        visibility: hidden;
        background-color: white;

        a {
          color: color(blackScale, 300) !important;
        }
      }
    }

    &__item {
      &--drop {
        &-item {
          a {
            align-items: flex-start;
          }

          &:not(:last-of-type) {
            border-bottom: 1px solid #e8e8e8;
            margin-left: unset;
          }
        }
      }
    }

    &__item,
    &__link {
      height: 100%;

      &--current a.nav__link {
        color: color(primary, 300) !important;
        font-weight: bold;
      }
    }
  }
}

.nav-mobile {
  @extend %transitionDefault;

  position: fixed;
  top: 0;
  right: -240px;
  z-index: 100;
  width: 240px;
  height: 100%;
  background-color: whitesmoke;
  box-shadow: 0px 4px 30px 0px #0000004D;

  &__overlay {
    @extend %transitionDefault;

    position: fixed;
    top: 0;
    right: -100%;
    z-index: 99;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }

  &__close {
    text-align: right;
    font-size: size(text, 500);
    margin: 2rem 1rem 0 0;
    color: #ED1B24;
  }

  &__button {
    color: #000000;
    width: 100%;
    font-family: Lato;
    font-size: 19px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    cursor: pointer;
  }

  @include for-desktop-up {
    position: static;
    width: 100%;
    background-color: transparent;
    box-shadow: 0px 0px 0px 0px #0000004D;

    &__button,
    &__close {
      display: none;
    }
  }

  &__active,
  &__active>&__overlay {
    right: 0;
  }
}