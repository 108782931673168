@use "../abstracts" as *;
@import "../../variables";

.header {
  width: 100%;
  z-index: 1;
  background-color: $white;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15);

  .top {
    background-color: color(primary, 300);
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.15);
    padding: 8px 0;

    @include for-phone-only {
      font-size: size(text, 200);
    }

    .list {
      gap: 8px;
      .list-social {
        display: flex;
        align-items: center;

        li {
          color: color(white, 100);
        }

        i {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 20px;
          margin-right: .5rem;
          background-color: white;
          color: #293C7A;
          border-radius: 50%;
          font-size: 10px;
        }
      }

      i {
        font-size: 16px;
        color: color(white, 100);
      }
    }
  }

  &__section {
    padding: 40px 0;

    &-content {
      @include for-phone-and-tablet {
        flex-direction: column-reverse;
      }
    }
  }

  &__simulate {
    @include flex(flex, row, center, center);
    background: linear-gradient(180deg, color(primary, 300) 0%, color(primary, 400) 48.33%, color(primary, 300) 100%);
    border-radius: 0 0 20px 20px;
    height: 67px;
    margin-top: -2rem;

    &-link {
      color: #fff;

      &:hover {
        color: #fff;
      }
    }

    @include for-phone-and-tablet {
      display: none;
    }
  }

  // &__fixed {
  //   @include for-tablet-landscape-up {
  //     position: fixed;
  //   }
  // }
}