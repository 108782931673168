@use "../abstracts" as *;
@import "../../variables";

.title {
  width: fit-content;
  margin: 0 auto;
  color: #000;
  font-size: 36px;
  font-weight: 700;

  &::before {
    content: "";
    float: left;
    width: 5px;
    height: 26px;
    margin-top: 8px;
    background-color: color(decoration, green);
  }

  &::after {
    content: "";
    float: left;
    width: 5px;
    height: 26px;
    margin-top: 8px;
    background-color: color(decoration, yellow);
    margin-right: 10px;
  }
}

.placeholder {
  background-color: color(placeholder);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.map {
  .embed-responsive {
    max-height: 283px;
  }
}