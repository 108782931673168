@use "./base";

// Components
@use "./components/header";
@use "./components/nav";
@use "./components/footer";
@use "./components/social-medias";
@use "./components/whats";
@use "./components/floating-form";
@use "./components/banner-pages";

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@100;200;300;400;500;700&display=swap');