@use "../abstracts" as *;
@import "../../variables";

.vertical-line {
  border: 1px solid color(white, 100);
  height: 56px;
}

.information {
  background-color: color(primary, 300);

  &__title {
    color: color(white, 100);
    font-weight: 900;
    font-size: size(text, 400);
    margin-bottom: 0.5rem;
  }

  &__item {
    color: color(white, 100);

    // &-city {
    //   color: color(secondary, 700);
    //   font-weight: 600;
    //   text-transform: uppercase;
    // }

    &:not(:last-of-type) {
      margin-bottom: 0.5rem;
    }

    &:hover {
      color: color(grayScale, 100); 
    }

    @include for-phone-only {
      justify-content: center;
    }
  }

  &__link {
    font-size: size(text);

    // &-phone {
    //   color: color(primary, 300);
    // }

    &:hover {
      color: color(grayScale, 100);
    }
  }

  // &__icon {
  //   @include flex(flex, row, center, center);
  //   background-color: white;
  //   width: 36px;
  //   border-radius: 50%;
  //   height: 36px;
  //   color: color(primary, 300);
  //   font-size: size(text);
  //   margin-right: 0.5rem;
  // }

  &__column {
    margin-bottom: 1rem;

    @include for-desktop-up {
      margin-bottom: unset;

      &:not(:last-of-type) {
        border-right: 2px solid white;
      }

      &:nth-child(2) {
        padding-left: 5rem;
      }
    }
  }

  @media (max-width: 1200px) {
    background-image: unset !important;
  }
}

.rights {
  &__text {
    &-w3 {
      @include for-phone-only {
        margin: auto;
      }
    }
  }

  align-items: center;
  min-height: 40px;
  padding: 0.5rem 0px;
  background-color: #d9d9d9;

  & p {
    color: color(blackScale, 200);

    @include for-phone-only {
      text-align: center;
    }
  }

  @include for-desktop-up {
    flex-direction: row;
  }

  @include for-phone-only {
    flex-direction: column;
    text-align: center;
  }
}