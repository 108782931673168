// Typography
$font-main: "Poppins", sans-serif;

$type-scale-key: 300 !default;

$type-scale: (
    text: (
        100: 0.75rem,
        200: 0.875rem,
        300: 1rem,
        400: 1.125rem,
        500: 1.25rem,
    ),
    title: (
        100: 1.563rem,
        200: 1.875rem,
        300: 2.25rem,
        400: 2.625rem,
        500: 3.25rem,
    ),
);
